<template>
  <div
    class="w-block__pre-heading"
    :class="{ 'ml-4 mr-4 mt-3': $voicer.isMobile }"
  >
    <!-- pre-heading block -->
    <v-container class="pa-0" v-if="isHomeActive">
      <v-sheet
        ref="sheet"
        color="#C9E4F1"
        class="pa-2"
        :class="{
          'v-sheet__desktop': $voicer.isDesktopOrTablet,
          'ma-3 mb-4': $voicer.isDesktopOrTablet,
        }"
      >
        <div
          class="text-alternate"
          :class="{
            'body-2 font-weight-normal': $voicer.isDesktopOrTablet,
            'body-2': $voicer.isMobile,
          }"
        >
          <div class="pl-2 pr-2 pt-1">
            Vous souhaitez partager une actu, poser une question, faire une
            dédicace à un de vos collègues ? Contactez nous au
            <a href="tel:+33186860686">01 86 86 06 86</a> ou par mail sur
            <a href="mailto:contact@pops-laradio.fr">contact@pops-laradio.fr</a>
          </div>
          <v-btn
            ref="btn"
            href="mailto:contact@pops-laradio.fr"
            color="#164194"
            rounded
            depressed
            class="ma-2 white--text"
          >
            <v-icon color="#ffffff" class="mr-2">
              mdi-comment-processing
            </v-icon>
            Nous écrire
          </v-btn>
        </div>
      </v-sheet>
    </v-container>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      showFeedbackModal: false,
    }
  },
  computed: {
    isHomeActive() {
      return this.$route.name === 'index'
    },
  },
}
</script>
<style lang="scss" scoped>
.w-block__pre-heading {
  justify-content: center;
  text-align: center;
  font-weight: bold;

  .v-sheet {
    text-align: left;
    border-radius: 8px;
  }
  .v-sheet__desktop {
    & > div {
      align-items: center;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;

      .v-btn {
        border-radius: 4px;
      }
    }
  }
}
.body-2 {
  text-align: center;
}
a {
  color: #164194;
}
</style>
