/**
 * configuration applied when BROCOLI_API_MODE = staging
 */
module.exports = {
  authMode: ['providers', 'local'],
  authOAuthProviders: [
    [
      'WSSOKeycloak',
      [
        {
          realm: 'LaPoste-preprod',
          clientId: 'vi-la-poste',
          buttonText: 'Connexion La Poste',
          autoLogin: true,
        },
      ],
    ],
  ],
}
